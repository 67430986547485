// Data request helpers
export const dataRequestKeys = [
  'ga-blockpartyapp',
  'ga-blockpartyapp-social',
  'ga-webstore',
  'installs-chrome',
  'installs-firefox',
  'installs-edge',
  'mixpanel-n-recommendations',
  'mixpanel-n-recommendations-unique-users',
  'mixpanel-n-settings-updates',
  'mixpanel-n-settings-updates-unique-users',
  'mixpanel-n-tools-run',
  'mixpanel-n-tools-run-unique-users',
  'mixpanel-signups',
  'mixpanel-plans',
  'mixpanel-n-page-views-privacypartyapp',
  'mixpanel-n-page-views-privacypartyapp-social',
  'mixpanel-n-page-views-privacypartyapp-email',
  'mixpanel-active-users',
  'mixpanel-first-time-active-users',
]

export const metricsTableKeys = dataRequestKeys.filter(
  (x) =>
    !['mixpanel-active-users', 'mixpanel-first-time-active-users'].includes(x),
)

export function getRequestUrlForKey(
  requestKey: string,
  options: { startDateAsStr?: string; endDateAsStr?: string },
) {
  switch (requestKey) {
    case 'ga-blockpartyapp':
      return (
        `/api/query-timeseries-data/?seriesName=new_users_blockpartyapp&` +
        `startDate=${options.startDateAsStr}&endDate=${options.endDateAsStr}`
      )
    case 'ga-blockpartyapp-social':
      return (
        `/api/query-timeseries-data/?seriesName=new_users_blockpartyapp_from_social&` +
        `startDate=${options.startDateAsStr}&endDate=${options.endDateAsStr}`
      )
    case 'ga-webstore':
      return (
        `/api/query-timeseries-data/?seriesName=new_users_webstore&` +
        `startDate=${options.startDateAsStr}&endDate=${options.endDateAsStr}`
      )
    case 'installs-chrome':
      return (
        `/api/query-timeseries-data/?seriesName=installs_chrome&` +
        `startDate=${options.startDateAsStr}&endDate=${options.endDateAsStr}`
      )
    case 'installs-firefox':
      return (
        `/api/query-timeseries-data/?seriesName=installs_firefox&` +
        `startDate=${options.startDateAsStr}&endDate=${options.endDateAsStr}`
      )
    case 'installs-edge':
      return (
        `/api/query-timeseries-data/?seriesName=installs_edge&` +
        `startDate=${options.startDateAsStr}&endDate=${options.endDateAsStr}`
      )
    case 'mixpanel-n-recommendations':
      return (
        `/api/query-timeseries-data/?seriesName=n_recommendations_completed&` +
        `startDate=${options.startDateAsStr}&endDate=${options.endDateAsStr}`
      )
    case 'mixpanel-n-recommendations-unique-users':
      return (
        `/api/query-timeseries-data/?seriesName=n_recommendations_completed_unique_users&` +
        `startDate=${options.startDateAsStr}&endDate=${options.endDateAsStr}`
      )
    case 'mixpanel-n-settings-updates':
      return (
        `/api/query-timeseries-data/?seriesName=n_settings_completed&` +
        `startDate=${options.startDateAsStr}&endDate=${options.endDateAsStr}`
      )
    case 'mixpanel-n-settings-updates-unique-users':
      return (
        `/api/query-timeseries-data/?seriesName=n_settings_completed_unique_users&` +
        `startDate=${options.startDateAsStr}&endDate=${options.endDateAsStr}`
      )
    case 'mixpanel-n-tools-run':
      return (
        `/api/query-timeseries-data/?seriesName=n_tool_runs_completed&` +
        `startDate=${options.startDateAsStr}&endDate=${options.endDateAsStr}`
      )
    case 'mixpanel-n-tools-run-unique-users':
      return (
        `/api/query-timeseries-data/?seriesName=n_tool_runs_completed_unique_users&` +
        `startDate=${options.startDateAsStr}&endDate=${options.endDateAsStr}`
      )
    case 'mixpanel-signups':
      return (
        `/api/query-timeseries-data/?seriesName=n_signups_completed&` +
        `startDate=${options.startDateAsStr}&endDate=${options.endDateAsStr}`
      )
    case 'mixpanel-plans':
      return (
        `/api/query-timeseries-data/?seriesName=n_plans_created&` +
        `startDate=${options.startDateAsStr}&endDate=${options.endDateAsStr}`
      )
    case 'mixpanel-n-page-views-privacypartyapp':
      return (
        `/api/query-timeseries-data/?seriesName=n_page_views_privacypartyapp&` +
        `startDate=${options.startDateAsStr}&endDate=${options.endDateAsStr}`
      )
    case 'mixpanel-n-page-views-privacypartyapp-social':
      return (
        `/api/query-timeseries-data/?seriesName=n_page_views_privacypartyapp_social&` +
        `startDate=${options.startDateAsStr}&endDate=${options.endDateAsStr}`
      )
    case 'mixpanel-n-page-views-privacypartyapp-email':
      return (
        `/api/query-timeseries-data/?seriesName=n_page_views_privacypartyapp_email&` +
        `startDate=${options.startDateAsStr}&endDate=${options.endDateAsStr}`
      )
    case 'mixpanel-active-users':
      return (
        `/api/query-timeseries-data/?seriesName=n_active_extension_users&` +
        `startDate=${options.startDateAsStr}&endDate=${options.endDateAsStr}`
      )
    case 'mixpanel-first-time-active-users':
      return (
        `/api/query-timeseries-data/?seriesName=n_first_time_active_extension_users&` +
        `startDate=${options.startDateAsStr}&endDate=${options.endDateAsStr}`
      )
    default:
      throw new Error(`Unrecognized request key: ${requestKey}`)
  }
}
