import { useEffect, useRef, useState } from 'react'

import {
  handleUpdateCurrentRecommendationDisplayIndex,
  sendUpdateCurrentRecommendationDisplayIndex,
} from '../utils/communication'
import { EXTENSION_MESSAGE_TYPES } from '../utils/enums'
import { getMessageType, validateExtensionMessage } from '../utils/messages'

/**
 * Returns a ref that can be used to track when a recommendation card is in view.
 *
 * When instantiating this hook, pass in the index of the recommendation card;
 * the index will be used in the IntersectionObserver's callback to tell the
 * sidebar checklist nav which card is in view.
 */
// @TODO: Delete this after 2.0 is launched. This is the old version that
// uses Chrome messaging. The new version is in the Sidebar2 component tree.
export function useCurrentRecommendationDisplayIndexRef(ix: number) {
  const ref = useRef<HTMLElement | null>(null)
  const [isCurrent, setIsCurrent] = useState<boolean>(false)

  useEffect(() => {
    if (typeof chrome !== 'undefined' && chrome.runtime) {
      const observer = new IntersectionObserver(
        (entries) => {
          entries.forEach((entry) => {
            if (entry.isIntersecting) {
              setIsCurrent(true)
              sendUpdateCurrentRecommendationDisplayIndex(ix)
            } else {
              setIsCurrent(false)
            }
          })
        },
        // Explanation of intersection observer arguments
        //
        // We set a root margin of -19% from the top and -80%
        // from the bottom so that the observer triggers about
        // 20% of the way down the viewport (more natural than
        // triggering as soon as cards enter the viewport).
        //
        // In order for root margin to work within IFrames
        // we additionally need to set root to "document" -
        // see https://github.com/w3c/IntersectionObserver/issues/372.
        {
          root: document,
          rootMargin: '-24% 0% -75% 0%',
          threshold: 0,
        },
      )

      if (ref.current) {
        observer.observe(ref.current)
        return () => observer.disconnect()
      }

      return () => {}
    }

    return () => {}
  }, [ix, ref])

  return {
    isCurrent,
    ref,
  }
}

// @TODO: Delete this after 2.0 is launched. This is the old version that
// uses Chrome messaging. The new version is in the Sidebar2 component tree.
export default function useCurrentRecommendationDisplayIndex(): {
  currentRecommendationDisplayIndex: number | null
} {
  const [
    currentRecommendationDisplayIndex,
    setCurrentRecommendationDisplayIndex,
  ] = useState<number | null>(null)

  useEffect(() => {
    if (chrome && chrome.runtime) {
      const handlerFn = (message, sender, sendResponse) => {
        const messageType = getMessageType(message)
        const messageValid = validateExtensionMessage(message, sender)

        if (messageValid) {
          switch (messageType) {
            case EXTENSION_MESSAGE_TYPES.PASS_ON_UPDATE_CURRENT_RECOMMENDATION_DISPLAY_INDEX:
              setCurrentRecommendationDisplayIndex(
                handleUpdateCurrentRecommendationDisplayIndex(
                  message,
                  sender,
                  sendResponse,
                ),
              )
              break
            default:
            // ignore if message type unrecognized
          }
        }
      }

      chrome.runtime.onMessage.addListener(handlerFn)

      return () => {
        chrome.runtime.onMessage.removeListener(handlerFn)
      }
    }

    return () => {}
  }, [])

  return {
    currentRecommendationDisplayIndex,
  }
}
