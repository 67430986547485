import React, {
  Dispatch,
  ReactNode,
  SetStateAction,
  useCallback,
  useEffect,
  useState,
} from 'react'

import clsx from 'clsx'
import CopyToClipboard from 'react-copy-to-clipboard'
import { scroller } from 'react-scroll'

import AutomatedRecommendationCardBody from './AutomatedRecommendationCardBody'
import FacebookPhotoUntaggingRecommendationCardBody from './FacebookPhotoUntaggingRecommendationCardBody'
import FbUnfriendRecommendationCardBody from './FbUnfriendRecommendationCardBody'
import ImpressionLogger from './ImpressionLogger'
import InteractiveElement from './InteractiveElement'
import LogContextProvider from './LogContextProvider'
import ManualRecommendationCardBody from './ManualRecommendationCardBody'
import { InstagramAccountTypeSubheader } from './RecommendationCardSubheaders'
import RecommendationCardTopBanner from './RecommendationCardTopBanner'
import RecommendationHeader from './RecommendationHeader'
import TaskRecommendationCardBody from './TaskRecommendationCardBody'
import Toast from './Toast'
import { startAutomationMachineForRecommendation } from '../automations/automationStageMachine'
import { InstagramAccountVisibility } from '../automations/instagram/accountEnums'
import { RecommendationAutomationDef } from '../automations/interfaces'
import { updatePlatformUserAccountData } from '../data/platform/platformUserAccountData'
import { useCurrentRecommendationDisplayIndexRef } from '../hooks/useCurrentRecommendationDisplayIndex'
import LinkIcon from '../icons/chain-link-icon.png'
import Confetti from '../icons/confetti.svg'
import SectionCollapseIcon from '../icons/section-collapse-icon.svg'
import SectionExpandIcon from '../icons/section-expand-icon.svg'
import RecommendationDisplayWrapper from '../recommendations/recommendationDisplayWrapper'
import { RecommendationCardDisplayData } from '../recommendations/recommendationProgressTypes'
import { RecommendationKeyEnum } from '../recommendations/recommendationTypes'
import * as state from '../state'
import * as communication from '../utils/communication'
import { getTimeSinceInSeconds } from '../utils/dateUtils'
import {
  AutomationType,
  FBRecommendationKey,
  PLATFORM,
  RecommendationCardType,
  RecommendationState,
  TwitterRecommendationKey,
} from '../utils/enums'

import './RecommendationCard.scss'

/**
 * Inform a user of the details of a recommendation, the current state of that
 * recommendation, and allow them to take action on it.
 */
export default function RecommendationCard({
  automationDef,
  displayPosition, // only used for metrics
  isLanguageSupported,
  isLessRelevant, // determined by personalization
  isPlaybookCompleted,
  lastDeeplinkRecommendationKey,
  locked = false,
  platform,
  progress,
  recommendationKey,
  spec,
  userId: platformUserId,
  setShowLoading,
}: {
  automationDef: RecommendationAutomationDef
  displayPosition: number
  isLanguageSupported: boolean
  isLessRelevant: boolean
  isPlaybookCompleted: boolean
  platform: string
  progress: RecommendationCardDisplayData
  recommendationKey: string
  spec: RecommendationDisplayWrapper
  userId: any
  lastDeeplinkRecommendationKey: string | null
  locked: boolean
  setShowLoading: Dispatch<SetStateAction<boolean>>
}) {
  const {
    bodyTitle,
    canUndo,
    concernTags,
    confirmTaskButtonText,
    continuingTaskButtonText,
    customActionDescription,
    explanationText,
    hasAutomatedGather,
    isLabParty,
    isPhoto,
    recommendationCardType,
    recommendationTitle,
    deeplinkShareUrl,
    settings,
    steps,
    taskButtonText,
    taskContext,
    taskTitle,
    taskUpdatedText,
    taskWarningText,
  } = spec
  const {
    automationErrors,
    initialState,
    recommendationState,
    lastGatheredTimestamp,
    lastUpdatedTimestamp,
    settingsSelected,
  } = progress

  const [showExplanation, setShowExplanation] = useState(
    recommendationState === RecommendationState.INCOMPLETE || isLabParty,
  )

  const { isCurrent, ref: currentRecommendationDisplayIndexRef } =
    useCurrentRecommendationDisplayIndexRef(displayPosition)

  const [isAnimatingCardStateTransition, setIsAnimatingCardStateTransition] =
    useState(false)
  const [
    animateFirstRenderForUpdatedCard,
    setAnimateFirstRenderForUpdatedCard,
  ] = useState(false)
  const [showConfetti, setShowConfetti] = useState(false)

  const scrollToAnchor = useCallback((anchorId: string, smooth: boolean) => {
    scroller.scrollTo(anchorId, {
      smooth,
      duration: 250,
    })
  }, [])

  const toggleShowExplanation = () =>
    setShowExplanation((prevValue) => !prevValue)

  const animateTransition = (callback) => {
    setIsAnimatingCardStateTransition(true)
    setTimeout(() => {
      callback()
      setIsAnimatingCardStateTransition(false)
    }, 500)
    scrollToAnchor(recommendationKey, true)
  }

  const isPostDeeplinkGather =
    recommendationKey === lastDeeplinkRecommendationKey &&
    lastGatheredTimestamp &&
    getTimeSinceInSeconds(lastGatheredTimestamp) <= 2

  const [showGlow, setShowGlow] = useState(false)

  useEffect(() => {
    if (isPostDeeplinkGather) {
      scrollToAnchor(recommendationKey, true)
      if (recommendationState === RecommendationState.INCOMPLETE) {
        setShowGlow(true)
        setTimeout(() => {
          setShowGlow(false)
        }, 5000)
      } else if (recommendationState === RecommendationState.FULFILLED) {
        setShowConfetti(true)
        setTimeout(() => {
          setShowConfetti(false)
        }, 1500)
      }
    }
  }, [
    setShowGlow,
    scrollToAnchor,
    isPostDeeplinkGather,
    recommendationKey,
    recommendationState,
  ])

  // When the completion state updates, trigger a toggle of show explanation
  useEffect(() => {
    setShowExplanation(
      recommendationState === RecommendationState.INCOMPLETE || isLabParty,
    )
    if (
      recommendationState === RecommendationState.UPDATED &&
      lastUpdatedTimestamp &&
      getTimeSinceInSeconds(lastUpdatedTimestamp) <= 2
    ) {
      if (recommendationCardType !== RecommendationCardType.MANUAL) {
        setAnimateFirstRenderForUpdatedCard(true)
        setTimeout(() => setAnimateFirstRenderForUpdatedCard(false), 500)
      }
      // Don't show confetti for Facebook unfriend or Twitter delete your posts
      if (
        recommendationKey !== FBRecommendationKey.FB_UNFRIEND &&
        recommendationKey !==
          TwitterRecommendationKey.TWITTER_DELETE_POSTS_BEFORE
      ) {
        setShowConfetti(true)
        setTimeout(() => {
          setShowConfetti(false)
        }, 1500)
      }
    }
  }, [
    recommendationState,
    scrollToAnchor,
    recommendationKey,
    setAnimateFirstRenderForUpdatedCard,
    recommendationCardType,
    lastUpdatedTimestamp,
    isLabParty,
  ])

  const setUpdateAutomation = useCallback(() => {
    startAutomationMachineForRecommendation(
      platform,
      platformUserId,
      recommendationKey,
      AutomationType.UPDATE,
      null,
      recommendationKey === lastDeeplinkRecommendationKey,
    )
  }, [
    lastDeeplinkRecommendationKey,
    platform,
    platformUserId,
    recommendationKey,
  ])

  const setUndoAutomation = () => {
    startAutomationMachineForRecommendation(
      platform,
      platformUserId,
      recommendationKey,
      AutomationType.UNDO,
      null,
    )
  }

  // On click handlers
  const onClickUpdate = useCallback(
    () =>
      communication
        .sendSetLastSidebarScrollPosition(
          platform,
          document.body.scrollTop,
          recommendationKey,
        )
        .then(() => {
          communication
            .sendSetActiveTabForPlatform(platform)
            .then(() => setUpdateAutomation())
        }),
    [platform, recommendationKey, setUpdateAutomation],
  )

  const onClickMarkAsDone = () => {
    animateTransition(() => {
      updatePlatformUserAccountData(platform as PLATFORM, platformUserId, {})
      state.setRecommendationStatus(
        platform,
        platformUserId,
        recommendationKey,
        {
          updated: true,
        },
      )
      setShowExplanation(false)
    })
  }

  const onClickSkip = () => {
    animateTransition(() => {
      updatePlatformUserAccountData(platform as PLATFORM, platformUserId, {})
      state.setRecommendationStatus(
        platform,
        platformUserId,
        recommendationKey,
        {
          skipped: true,
        },
      )
      setShowExplanation(false)
    })
  }

  const onClickUndoAutomated = () => {
    communication
      .sendSetLastSidebarScrollPosition(
        platform,
        document.body.scrollTop,
        recommendationKey,
      )
      .then(() => {
        communication
          .sendSetActiveTabForPlatform(platform)
          .then(() => setUndoAutomation())
      })
  }

  const onClickUndoManual = () => {
    animateTransition(() => {
      state.setRecommendationStatus(
        platform,
        platformUserId,
        recommendationKey,
        {
          undoUpdated: true,
        },
      )
      setShowExplanation(true)
    })
  }

  const onClickUndoMarkAsSkipped = () => {
    animateTransition(() => {
      state.setRecommendationStatus(
        platform,
        platformUserId,
        recommendationKey,
        {
          undoSkipped: true,
        },
      )
      setShowExplanation(true)
    })
  }

  const onClickCheckSettings = useCallback(() => {
    const setGatherAutomation = () => {
      startAutomationMachineForRecommendation(
        platform,
        platformUserId,
        recommendationKey,
        AutomationType.GATHER,
        null,
      )
    }

    // Disallow running the automation if the sidebar is in a locked state
    if (!locked) {
      communication
        .sendSetLastSidebarScrollPosition(
          platform,
          document.body.scrollTop,
          recommendationKey,
        )
        .then(() => {
          communication
            .sendSetActiveTabForPlatform(platform)
            .then(() => setGatherAutomation())
        })
    }
  }, [locked, platform, platformUserId, recommendationKey])

  const onConcernTagClick = (concernTag: string) => {
    setShowLoading(true)
    communication.sendSetLastSidebarConcernTagFilterDimension(platform, [
      concernTag,
    ])
  }

  const handleTaskConfirmClick = useCallback(async () => {
    await communication.sendSetActiveTabForPlatform(platform)
    // Experiment state management and flows may break our normal assumptions.
    // Ensure progress for this recommendation is initialized before update.
    await state.updateRecommendation(
      platform,
      platformUserId,
      recommendationKey as RecommendationKeyEnum,
      {},
      { doNotUpdateLastUpdatedTimestamp: true },
    )
    onClickUpdate()
  }, [platform, platformUserId, recommendationKey, onClickUpdate])

  let subheader
  if (platform === PLATFORM.INSTAGRAM) {
    // If this recommendation is only for public accounts
    if (spec.accountTypes?.length === 1 && spec.accountTypes[0] === 'Public') {
      subheader = (
        <InstagramAccountTypeSubheader
          type={InstagramAccountVisibility.PUBLIC}
        />
      )
    }
  }

  let addlExplanationTextClassName
  if (showExplanation === null) {
    addlExplanationTextClassName = 'initial'
  } else if (showExplanation === false) {
    addlExplanationTextClassName = 'hide'
  } else {
    addlExplanationTextClassName = 'show'
  }

  const addClickable = explanationText ? '' : 'not-clickable'

  let content: ReactNode | undefined
  switch (recommendationCardType) {
    case RecommendationCardType.AUTOMATED:
      content = (
        <AutomatedRecommendationCardBody
          animateFirstRenderForUpdatedCard={animateFirstRenderForUpdatedCard}
          isAnimatingCardStateTransition={isAnimatingCardStateTransition}
          automationDef={automationDef}
          automationErrors={automationErrors}
          canUndo={canUndo}
          customActionDescription={customActionDescription}
          defaultBodyTitle={bodyTitle}
          initialState={initialState}
          isLanguageSupported={isLanguageSupported}
          isLessRelevant={isLessRelevant}
          isPhoto={isPhoto}
          lastGatheredTimestamp={lastGatheredTimestamp}
          lastUpdatedTimestamp={lastUpdatedTimestamp}
          onClickAction={onClickUpdate}
          onClickScanSettings={onClickCheckSettings}
          onClickSkip={onClickSkip}
          onClickUndo={onClickUndoAutomated}
          onClickUndoSkip={onClickUndoMarkAsSkipped}
          platform={platform}
          recommendationKey={recommendationKey}
          recommendationState={recommendationState}
          settings={settings}
          settingsSelected={settingsSelected}
          truncate={isPostDeeplinkGather || false}
          userId={platformUserId}
          locked={locked || !isLanguageSupported}
          showGlow={showGlow}
        />
      )
      break
    case RecommendationCardType.FACEBOOK_PHOTO_UNTAGGING:
      content = (
        <FacebookPhotoUntaggingRecommendationCardBody
          animateFirstRenderForUpdatedCard={animateFirstRenderForUpdatedCard}
          isAnimatingCardStateTransition={isAnimatingCardStateTransition}
          customActionDescription={customActionDescription}
          defaultBodyTitle={bodyTitle}
          initialState={initialState}
          recommendationState={recommendationState}
          lastGatheredTimestamp={lastGatheredTimestamp}
          platform={platform}
          platformUserId={platformUserId}
          recommendationKey={recommendationKey}
          onClickCheckSettings={onClickCheckSettings}
          onClickMarkAsDone={onClickMarkAsDone}
          onClickSkip={onClickSkip}
          onClickUndoMarkAsDone={onClickUndoManual}
          onClickUndoSkip={onClickUndoMarkAsSkipped}
          locked={locked || !isLanguageSupported}
        />
      )
      break
    case RecommendationCardType.TASK:
      if (recommendationKey !== FBRecommendationKey.FB_UNFRIEND) {
        content = (
          <TaskRecommendationCardBody
            automationErrors={automationErrors}
            confirmTaskButtonText={confirmTaskButtonText}
            continuingTaskButtonText={continuingTaskButtonText}
            initialState={initialState}
            lastUpdatedTimestamp={lastUpdatedTimestamp}
            locked={Boolean(locked)}
            onConfirmClick={handleTaskConfirmClick}
            platformUserId={platformUserId}
            recommendationKey={recommendationKey}
            recommendationState={recommendationState}
            settingsSelected={settingsSelected}
            statusText={automationErrors?.message || initialState?.message}
            taskButtonText={taskButtonText}
            taskContext={taskContext}
            taskTitle={taskTitle}
            taskUpdatedText={taskUpdatedText}
            taskWarningText={taskWarningText}
          />
        )
      } else {
        content = (
          <FbUnfriendRecommendationCardBody
            locked={Boolean(locked)}
            platformUserId={platformUserId}
            progress={progress}
          />
        )
      }
      break
    default:
      content = (
        <ManualRecommendationCardBody
          isAnimatingCardStateTransition={isAnimatingCardStateTransition}
          customActionDescription={customActionDescription}
          defaultBodyTitle={bodyTitle}
          recommendationState={recommendationState}
          onClickAction={onClickMarkAsDone}
          onClickSkip={onClickSkip}
          onClickUndo={onClickUndoManual}
          onClickUndoSkip={onClickUndoMarkAsSkipped}
          steps={steps}
          locked={locked}
        />
      )
      break
  }

  const [showCopyToast, setShowCopyToast] = useState(false)
  const handleCopyDeeplink = useCallback(() => {
    if (deeplinkShareUrl) {
      navigator.clipboard.writeText(deeplinkShareUrl)
    }
    setShowCopyToast(true)
    setTimeout(() => {
      setShowCopyToast(false)
    }, 2000)
  }, [deeplinkShareUrl])

  const logEventProps = {
    component: 'RecommendationCard',
    cardType: recommendationCardType,
    concernTags,
    displayPosition,
    hasAutomatedGather,
    isLocked: locked,
    isLessRelevant,
    isNotGathered: !initialState,
    platform,
    recommendationKey,
    recommendationState,
    settingsSelected: settingsSelected
      ? Object.keys(settingsSelected).filter(
          (setting) => settingsSelected[setting],
        )
      : [],
  }

  return (
    <LogContextProvider {...logEventProps}>
      <ImpressionLogger
        eventName="recommendation:view"
        eventProps={logEventProps}
      >
        <section
          aria-labelledby={`${recommendationKey}-title`}
          className={clsx(
            'RecommendationCard',
            isCurrent && !locked && 'current-display',
          )}
          ref={currentRecommendationDisplayIndexRef}
        >
          {showCopyToast && <Toast color="green" text="Link copied" />}
          {showConfetti && <Confetti className="confetti" />}
          <span
            className={clsx(
              'anchor',
              isPlaybookCompleted && 'playbook-completed',
              isLabParty && 'is-lab-party-sidebar',
            )}
            id={recommendationKey}
          />
          <RecommendationHeader
            concernTags={concernTags}
            hasAutomatedGather={hasAutomatedGather}
            isLabParty={isLabParty}
            isLanguageSupported={isLanguageSupported}
            isLessRelevant={isLessRelevant}
            isPhoto={isPhoto}
            locked={locked || !isLanguageSupported}
            onConcernTagClick={onConcernTagClick}
            onReScanClick={onClickCheckSettings}
            recommendationCardType={recommendationCardType}
            recommendationState={recommendationState}
          />
          {recommendationCardType === RecommendationCardType.TASK &&
            platform === PLATFORM.TWITTER &&
            initialState?.result && (
              <RecommendationCardTopBanner
                title={initialState.result.title}
                detail={initialState.result.detail}
                footerText={initialState.result.helper?.text}
                footerLink={initialState.result.helper?.link}
                onClickClose={() => {
                  const updatedInitialState = { ...initialState }
                  delete updatedInitialState.result

                  state.updateRecommendation(
                    platform,
                    platformUserId,
                    recommendationKey as RecommendationKeyEnum,
                    { initialState: updatedInitialState },
                    { doNotUpdateLastUpdatedTimestamp: true },
                  )
                }}
              />
            )}
          <div className={clsx('recommendation-title-wrapper', addClickable)}>
            {subheader}
            <div className="recommendation-title">
              <h3
                className={clsx(
                  'title',
                  'heading3',
                  'bold',
                  'black',
                  showGlow && 'glow',
                )}
              >
                <div className="title-text">{recommendationTitle}</div>
                {deeplinkShareUrl && (
                  <InteractiveElement
                    className="share-url"
                    aria-label={`Copy ${recommendationKey} deeplink`}
                    role="button"
                  >
                    <CopyToClipboard
                      onCopy={handleCopyDeeplink}
                      text={deeplinkShareUrl}
                    >
                      <img
                        alt="copy link"
                        srcSet={LinkIcon}
                        className="link-icon"
                      />
                    </CopyToClipboard>
                  </InteractiveElement>
                )}
              </h3>
              {explanationText && !showExplanation ? (
                <InteractiveElement
                  aria-label="Show Explanation"
                  aria-expanded={showExplanation}
                  className="toggle-explanation-button"
                  onClick={toggleShowExplanation}
                >
                  <SectionExpandIcon
                    aria-hidden
                    className="toggle-explanation-icon"
                  />
                </InteractiveElement>
              ) : (
                <InteractiveElement
                  aria-label="Hide Explanation"
                  aria-expanded={showExplanation}
                  className="toggle-explanation-button"
                  onClick={toggleShowExplanation}
                >
                  <SectionCollapseIcon
                    aria-hidden
                    className="toggle-explanation-icon"
                  />
                </InteractiveElement>
              )}
            </div>
            <div
              className={`recommendation-explanation-text ${addlExplanationTextClassName}`}
            >
              <span className="body2">{explanationText}</span>
            </div>
          </div>
          {content}
        </section>
      </ImpressionLogger>
    </LogContextProvider>
  )
}
