const BuildConfig = {
  APP_NAME: process.env.APP_NAME,
  BROWSER: process.env.BROWSER,
  EXTENSION_API_ORIGIN: process.env.EXTENSION_API_ORIGIN,
  MARKETING_SITE_ORIGIN: process.env.MARKETING_SITE_ORIGIN,
  REACT_APP_ENV: process.env.REACT_APP_ENV,
  STAGING_BASIC_AUTH: process.env.STAGING_BASIC_AUTH,
}

export default BuildConfig
