export function chromeRuntime(): boolean {
  return typeof chrome !== 'undefined' && chrome.runtime !== undefined
}

export function chromeStorage(): boolean {
  return (
    typeof chrome !== 'undefined' &&
    chrome.storage?.local !== undefined &&
    chrome.storage?.onChanged !== undefined
  )
}

export function chromeTabs(): boolean {
  return typeof chrome !== 'undefined' && chrome.tabs !== undefined
}
