import React, { ReactNode } from 'react'

import clsx from 'clsx'

import ToDoCheckbox from './ToDoCheckbox'
import CheckmarkDoneIcon from '../icons/checkmark-done-icon.svg'
import CheckMarkIcon from '../icons/checkmark-icon-small.svg'
import CheckmarkUpdatedDoneIcon from '../icons/checkmark-updated-done-icon.svg'
import IncompleteIcon from '../icons/incomplete-icon.svg'
import WarningIcon from '../icons/warning-icon.svg'
import { RecommendationState } from '../utils/enums'

import './RecommendationCardSettingRow.scss'

export default function RecommendationCardSettingRow({
  currentValueDescription,
  isLessRelevant,
  isSettingDisabled,
  isSettingDisabledReason,
  isSettingFulfilled,
  isSettingNotUndoable,
  isSettingSelected,
  label,
  labelId,
  recommendationState,
  onSelectSetting,
  locked = false,
}: {
  currentValueDescription: ReactNode | null
  isLessRelevant: boolean
  isSettingDisabled: boolean
  isSettingDisabledReason: ReactNode | null
  isSettingFulfilled: boolean
  isSettingNotUndoable: boolean
  isSettingSelected: boolean
  label: ReactNode
  labelId: string
  recommendationState: RecommendationState
  onSelectSetting: () => void
  locked: boolean
}) {
  // Default values for setting icon and label after recommendation is gathered
  const fulfilledIcon = isLessRelevant ? (
    <div role="img" className="setting-icon-wrapper" aria-labelledby={labelId}>
      <CheckMarkIcon className="setting-icon less-relevant" aria-hidden />
    </div>
  ) : (
    <div role="img" className="setting-icon-wrapper" aria-labelledby={labelId}>
      <CheckmarkDoneIcon className="setting-icon" aria-hidden />
    </div>
  )

  let icon = isSettingFulfilled ? (
    fulfilledIcon
  ) : (
    <ToDoCheckbox
      disabled={locked || isSettingDisabled}
      isSettingSelected={isSettingSelected && !isSettingDisabled}
      labelId={labelId}
      onClick={onSelectSetting}
    />
  )
  let labelTextColorClassName = isSettingDisabled ? 'darkest-gray' : 'black'

  // Recommendation is complete
  if (recommendationState !== RecommendationState.INCOMPLETE && !locked) {
    labelTextColorClassName = 'darkest-gray'

    // If setting was not already fulfilled, update icon accordingly
    if (!isSettingFulfilled) {
      if (
        recommendationState === RecommendationState.SKIPPED ||
        !isSettingSelected ||
        isSettingDisabled
      ) {
        icon = (
          <div
            role="img"
            className="setting-icon-wrapper"
            aria-labelledby={labelId}
          >
            <IncompleteIcon className="setting-icon" aria-hidden />
          </div>
        )
      } else if (isLessRelevant) {
        icon = (
          <div
            role="img"
            className="setting-icon-wrapper"
            aria-labelledby={labelId}
          >
            <CheckMarkIcon className="setting-icon less-relevant" aria-hidden />
          </div>
        )
      } else {
        icon = (
          <div
            role="img"
            className="setting-icon-wrapper"
            aria-labelledby={labelId}
          >
            <CheckmarkUpdatedDoneIcon className="setting-icon" aria-hidden />
          </div>
        )
      }
    }
  }

  let settingDescription: ReactNode = null
  if (locked) {
    settingDescription = 'Current Setting: -'
  } else if (isSettingDisabled) {
    settingDescription = isSettingDisabledReason
  } else if (
    recommendationState === RecommendationState.UPDATED &&
    isSettingNotUndoable
  ) {
    settingDescription = "Block Party can't undo this change"
  } else if (
    (recommendationState === RecommendationState.INCOMPLETE ||
      !isSettingSelected) &&
    currentValueDescription
  ) {
    settingDescription = <>Current Setting: {currentValueDescription}</>
  } else if (isSettingNotUndoable) {
    settingDescription = 'This info will be permanently changed'
  }

  const cantUndoIcon = (
    <div className="cant-undo-warning">
      <WarningIcon className="warning-icon" aria-label="Warning: can't undo" />
      <div className="tooltip-text body3">
        If you change your mind after updating, Block Party
        <br />
        won't be able to undo changes to this setting
      </div>
    </div>
  )

  return (
    <div className="RecommendationCardSettingRow d-flex flex-row">
      {icon}
      <div className="setting-text-wrapper d-flex flex-column">
        <div
          className={clsx('setting-label', 'body2', labelTextColorClassName)}
          aria-hidden
          id={labelId}
        >
          {label}
        </div>
        {settingDescription ? (
          <div className="setting-description-wrapper">
            <div className="body3 dark-gray">{settingDescription}</div>
            {recommendationState === RecommendationState.INCOMPLETE &&
              isSettingNotUndoable &&
              !isSettingDisabled &&
              cantUndoIcon}
          </div>
        ) : null}
      </div>
    </div>
  )
}
