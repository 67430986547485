import {
  throwIfGlobalInterruptRequested,
  resetGlobalInterruptRequest,
  GlobalInterrupt,
} from './globalInterrupt'
import {
  AutomationSequenceDefs,
  AutomationStageMachineDef,
  Machine,
  MachineContext,
  PlatformAutomationMachineStorageKeys,
  PlatformMachineStorageKeysMap,
  UserContext,
  Stage,
  StageDef,
  StageRegistry,
  StageStatus,
  AdditionalMachineData,
} from './interfaces'
import { createStageMachine } from './machine'
import { NeedsUserInputError } from './needsInputError'
import {
  DEFAULT_ACTION_STAGE_TIMEOUT,
  NO_TIMEOUT,
  REDIRECT_RETRY_MAX,
} from '../consts/stageMachineConsts'
import * as state from '../state'
import * as communication from '../utils/communication'
import {
  AutomationType,
  ControlStageKey,
  MACHINE_STORAGE_KEY_SUFFIX,
  PLATFORM,
  STORAGE_KEY,
  StageType,
  TriggeringEventType,
} from '../utils/enums'
import * as localStorage from '../utils/localStorage'
import { _debug } from '../utils/logging'
import { logError, logInfo } from '../utils/loggingUtils'
import { throwIfTimeout } from '../utils/promises'
import { remoteLog } from '../utils/remoteLog'

// Platform automation machine storage keys

const facebookMachineStorageKeys: PlatformAutomationMachineStorageKeys = {
  machineKeyStorageKey: STORAGE_KEY.FACEBOOK_MACHINE_KEY,
  machineOverlayStatusStorageKey: STORAGE_KEY.FACEBOOK_MACHINE_OVERLAY_STATUS,
  machineContextStorageKey: STORAGE_KEY.FACEBOOK_MACHINE_CONTEXT,
  machineSignalStorageKey: STORAGE_KEY.FACEBOOK_MACHINE_SIGNAL,
}

const googleMachineStorageKeys: PlatformAutomationMachineStorageKeys = {
  machineKeyStorageKey: STORAGE_KEY.GOOGLE_MACHINE_KEY,
  machineOverlayStatusStorageKey: STORAGE_KEY.GOOGLE_MACHINE_OVERLAY_STATUS,
  machineContextStorageKey: STORAGE_KEY.GOOGLE_MACHINE_CONTEXT,
  machineSignalStorageKey: STORAGE_KEY.GOOGLE_MACHINE_SIGNAL,
}

const instagramMachineStorageKeys: PlatformAutomationMachineStorageKeys = {
  machineKeyStorageKey: STORAGE_KEY.INSTAGRAM_MACHINE_KEY,
  machineOverlayStatusStorageKey: STORAGE_KEY.INSTAGRAM_MACHINE_OVERLAY_STATUS,
  machineContextStorageKey: STORAGE_KEY.INSTAGRAM_MACHINE_CONTEXT,
  machineSignalStorageKey: STORAGE_KEY.INSTAGRAM_MACHINE_SIGNAL,
}

const linkedinMachineStorageKeys: PlatformAutomationMachineStorageKeys = {
  machineKeyStorageKey: STORAGE_KEY.LINKEDIN_MACHINE_KEY,
  machineOverlayStatusStorageKey: STORAGE_KEY.LINKEDIN_MACHINE_OVERLAY_STATUS,
  machineContextStorageKey: STORAGE_KEY.LINKEDIN_MACHINE_CONTEXT,
  machineSignalStorageKey: STORAGE_KEY.LINKEDIN_MACHINE_SIGNAL,
}

const redditMachineStorageKeys: PlatformAutomationMachineStorageKeys = {
  machineKeyStorageKey: STORAGE_KEY.REDDIT_MACHINE_KEY,
  machineOverlayStatusStorageKey: STORAGE_KEY.REDDIT_MACHINE_OVERLAY_STATUS,
  machineContextStorageKey: STORAGE_KEY.REDDIT_MACHINE_CONTEXT,
  machineSignalStorageKey: STORAGE_KEY.REDDIT_MACHINE_SIGNAL,
}

const stravaMachineStorageKeys: PlatformAutomationMachineStorageKeys = {
  machineKeyStorageKey: STORAGE_KEY.STRAVA_MACHINE_KEY,
  machineOverlayStatusStorageKey: STORAGE_KEY.STRAVA_MACHINE_OVERLAY_STATUS,
  machineContextStorageKey: STORAGE_KEY.STRAVA_MACHINE_CONTEXT,
  machineSignalStorageKey: STORAGE_KEY.STRAVA_MACHINE_SIGNAL,
}

const twitterMachineStorageKeys: PlatformAutomationMachineStorageKeys = {
  machineKeyStorageKey: STORAGE_KEY.TWITTER_MACHINE_KEY,
  machineOverlayStatusStorageKey: STORAGE_KEY.TWITTER_MACHINE_OVERLAY_STATUS,
  machineContextStorageKey: STORAGE_KEY.TWITTER_MACHINE_CONTEXT,
  machineSignalStorageKey: STORAGE_KEY.TWITTER_MACHINE_SIGNAL,
}

const venmoMachineStorageKeys: PlatformAutomationMachineStorageKeys = {
  machineKeyStorageKey: STORAGE_KEY.VENMO_MACHINE_KEY,
  machineOverlayStatusStorageKey: STORAGE_KEY.VENMO_MACHINE_OVERLAY_STATUS,
  machineContextStorageKey: STORAGE_KEY.VENMO_MACHINE_CONTEXT,
  machineSignalStorageKey: STORAGE_KEY.VENMO_MACHINE_SIGNAL,
}

const youtubeMachineStorageKeys: PlatformAutomationMachineStorageKeys = {
  machineKeyStorageKey: STORAGE_KEY.YOUTUBE_MACHINE_KEY,
  machineOverlayStatusStorageKey: STORAGE_KEY.YOUTUBE_MACHINE_OVERLAY_STATUS,
  machineContextStorageKey: STORAGE_KEY.YOUTUBE_MACHINE_CONTEXT,
  machineSignalStorageKey: STORAGE_KEY.YOUTUBE_MACHINE_SIGNAL,
}

const platformToStorageKeysMap: PlatformMachineStorageKeysMap = {
  [PLATFORM.FACEBOOK]: facebookMachineStorageKeys,
  [PLATFORM.GOOGLE]: googleMachineStorageKeys,
  [PLATFORM.INSTAGRAM]: instagramMachineStorageKeys,
  [PLATFORM.LINKEDIN]: linkedinMachineStorageKeys,
  [PLATFORM.REDDIT]: redditMachineStorageKeys,
  [PLATFORM.STRAVA]: stravaMachineStorageKeys,
  [PLATFORM.TWITTER]: twitterMachineStorageKeys,
  [PLATFORM.VENMO]: venmoMachineStorageKeys,
  [PLATFORM.YOUTUBE]: youtubeMachineStorageKeys,
}

// Local Storage Functions
export function getAutomationMachineStorageKey(platform: string) {
  return platformToStorageKeysMap[platform].machineKeyStorageKey
}

export function getAutomationMachineOverlayStorageKey(platform: string) {
  return platformToStorageKeysMap[platform].machineOverlayStatusStorageKey
}

export function getAutomationMachineContextStorageKey(platform: string) {
  const contextKey = state.constructMachineContextKey(platform)
  if (
    contextKey !== platformToStorageKeysMap[platform].machineContextStorageKey
  ) {
    _debug(
      `configured context key does not match ${contextKey}`,
      'automation stage machine',
      'd',
    )
  }
  return contextKey
}

function constructMachineInputDataKey(machineName) {
  return machineName + MACHINE_STORAGE_KEY_SUFFIX.INPUT_DATA
}

function constructMachineResumeDataKey(machineName) {
  return machineName + MACHINE_STORAGE_KEY_SUFFIX.RESUME_DATA
}

function constructMachinePersistentDataKey(machineName) {
  return machineName + MACHINE_STORAGE_KEY_SUFFIX.PERSISTENT_DATA
}

export function getAutomationMachineInputDataStorageKey(platform: string) {
  const inputDataKey = constructMachineInputDataKey(platform)
  return inputDataKey
}

export function getAutomationMachineResumeDataStorageKey(platform: string) {
  const resumeDataKey = constructMachineResumeDataKey(platform)
  return resumeDataKey
}

export function getAutomationMachinePersistentDataStorageKey(platform: string) {
  const stageDataKey = constructMachinePersistentDataKey(platform)
  return stageDataKey
}

export function getAutomationMachineSignalStorageKey(platform: string) {
  const signalKey = communication.constructMachineSignalKey(platform)
  if (
    signalKey !== platformToStorageKeysMap[platform].machineSignalStorageKey
  ) {
    _debug(
      `configured signal key does not match ${signalKey}`,
      'automation stage machine',
      'd',
    )
  }
  return signalKey
}

export function getStoredAutomationMachineKey(
  platform: string,
): Promise<string | null> {
  const machineKeyStorageKey = getAutomationMachineStorageKey(platform)

  return localStorage.getSingle(machineKeyStorageKey)
}

function setStoredAutomationMachineKey(
  platform: string,
  machineKey: string | null,
): Promise<void> {
  const machineKeyStorageKey = getAutomationMachineStorageKey(platform)

  return localStorage.set({
    [machineKeyStorageKey]: machineKey,
  })
}

export function setStoredMachineOverlayStatus(
  platform: string,
  status: StageStatus,
): Promise<void> {
  const machineOverlayStatusStorageKey =
    getAutomationMachineOverlayStorageKey(platform)
  return localStorage.set({ [machineOverlayStatusStorageKey]: status })
}

export function getStoredMachineOverlayStatus(
  platform: string,
): Promise<StageStatus | null> {
  const machineOverlayStatusStorageKey =
    getAutomationMachineOverlayStorageKey(platform)

  return localStorage.getSingle(machineOverlayStatusStorageKey)
}

function setStoredAutomationMachineInputData(
  platform: string,
  data: any,
): Promise<void> {
  const key = getAutomationMachineInputDataStorageKey(platform)
  return localStorage.set({ [key]: data })
}

export function setStoredAutomationMachineResumeData(
  platform: string,
  data: any,
): Promise<void> {
  const key = getAutomationMachineResumeDataStorageKey(platform)
  return localStorage.set({ [key]: data })
}

export function setStoredAutomationMachinePersistentData(
  platform: string,
  data: any,
): Promise<void> {
  const key = getAutomationMachinePersistentDataStorageKey(platform)
  return localStorage.set({ [key]: data })
}

async function automationStageMachineCleanup(platform) {
  await Promise.all([
    setStoredAutomationMachineKey(platform, null),
    setStoredMachineOverlayStatus(platform, null),
    setStoredAutomationMachineInputData(platform, null),
    setStoredAutomationMachineResumeData(platform, null),
    setStoredAutomationMachinePersistentData(platform, null),
    communication.sendClearActiveTabForPlatform(platform),
    resetGlobalInterruptRequest(),
  ])
}

export async function startAutomationMachineForRecommendation(
  platform: string,
  platformUserId: string,
  recommendationKey: string,
  automationType: AutomationType,
  inputData: any,
  isDeeplink = false,
): Promise<void> {
  const machineKey = `${recommendationKey}___${automationType}`

  const currentDeeplinks = await localStorage.get(
    STORAGE_KEY.DEEPLINK_RECOMMENDATION_KEYS,
  )
  if (isDeeplink) {
    await localStorage.set({
      [STORAGE_KEY.DEEPLINK_RECOMMENDATION_KEYS]: {
        ...currentDeeplinks[STORAGE_KEY.DEEPLINK_RECOMMENDATION_KEYS],
        [platform]: recommendationKey,
      },
    })
  } else {
    await localStorage.set({
      [STORAGE_KEY.DEEPLINK_RECOMMENDATION_KEYS]: {
        ...currentDeeplinks[STORAGE_KEY.DEEPLINK_RECOMMENDATION_KEYS],
        [platform]: null,
      },
    })
  }

  const machineStorageKey = getAutomationMachineStorageKey(platform)

  await localStorage.remove(machineStorageKey)
  await localStorage.set({ [machineStorageKey]: machineKey })

  if (inputData) {
    await setStoredAutomationMachineInputData(platform, {
      ...inputData,
      platformUserId,
    })
  }
}

// Helper functions for constructing stages
function equalsCurrentUrl(url: string): boolean {
  const trailingSlashStrippedUrl = url.replace(/\/$/, '')
  const windowLocationHref = window.location.href.replace(/\/$/, '')
  return trailingSlashStrippedUrl === windowLocationHref
}

function matchesCurrentUrl(urlPatternRegExp: RegExp): boolean {
  const windowLocationHref = window.location.href.replace(/\/$/, '')
  return urlPatternRegExp.test(windowLocationHref)
}

function isUrlSet(url, urlPattern) {
  return (
    (urlPattern ? matchesCurrentUrl(new RegExp(urlPattern)) : false) ||
    equalsCurrentUrl(url)
  )
}

function getDebugContext(
  machineContext: MachineContext,
  userContext: UserContext,
) {
  return {
    currentUrl: window.location.href,
    machineContext,
    platform: userContext.platform,
    userId: userContext.userId,
  }
}

// Functions for creating stages

function createActionStage(
  {
    type,
    key,
    status,
    url,
    urlPattern,
    action,
    waitMs,
    timeout,
    transitionNextStageMap,
  }: StageDef,
  onTriggeringEvent: (
    trigger: TriggeringEventType,
    machineContext: MachineContext,
    userContext: UserContext,
    additionalMachineData: AdditionalMachineData,
  ) => Promise<void>,
  defaultNextStageKey: string,
  defaultPausedStageKey: string,
  defaultErroredStageKey: string,
  defaultNeedsUserInputStageKey: string,
): Stage {
  const stage: Stage = {
    type,
    key,
    status,
    actions: {
      onEnter: async (machineContext, userContext, additionalMachineData) => {
        const {
          machineName: platform,
          currentStageKey,
          stageKeyHistoryStack,
          redirectRetryCount,
        } = machineContext
        remoteLog.info(`${platform}: ${currentStageKey}`)
        _debug(`stage.onEnter ${key}`, 'automation stage machine', 'd')
        _debug(
          { platform, currentStageKey, stageKeyHistoryStack },
          'automation stage machine',
          'd',
        )

        // If the stage requires being on a particular url, navigate
        // there now; when the page refreshes, the stage machine will
        // return to the top of this stage and the onEnter code will
        // run again
        if (url && !isUrlSet(url, urlPattern)) {
          _debug(
            `check if url correct (current: ${window.location.href}, ` +
              `expected: ${url}, ${urlPattern}`,
            'automation stage machine',
            'd',
          )
          if (redirectRetryCount < REDIRECT_RETRY_MAX) {
            await state.updateMachineContext(platform, {
              ...machineContext,
              redirectRetryCount: redirectRetryCount + 1,
            })
            await communication.changeUrlAndWait(url)
          } else {
            const error = new Error(
              `Redirect retry reached for ${url} ` +
                `and pattern ${urlPattern} at current url=${window.location.href}`,
            )
            remoteLog.error(`${platform}: ${currentStageKey}: ${error}`)
            logError(error, 'automation-stage-machine')
            return onTriggeringEvent(
              TriggeringEventType.ON_ERROR,
              machineContext,
              userContext,
              additionalMachineData,
            )
          }
        }
        _debug(`stage.onEnter URL Set ${url}`, 'automation stage machine', 'd')

        // Set the status for the overlay
        if (status) {
          setStoredMachineOverlayStatus(platform, status)
        }
        _debug(
          'stage.onEnter overlay status set',
          'automation stage machine',
          'd',
        )

        // Sometimes we need to slow down our automations to avoid
        // triggering platform-side rate limits
        if (waitMs !== null && waitMs !== undefined && waitMs > 0) {
          await new Promise((resolve) => {
            setTimeout(resolve, waitMs)
          })
        }

        const actionTimeout = timeout || DEFAULT_ACTION_STAGE_TIMEOUT

        try {
          _debug(
            'stage.onEnter checking interrupt',
            'automation stage machine',
            'd',
          )
          throwIfGlobalInterruptRequested()
          _debug(
            'stage.onEnter executing action',
            'automation stage machine',
            'd',
          )

          if (actionTimeout === NO_TIMEOUT) {
            await action(machineContext, userContext, additionalMachineData)
          } else {
            await Promise.race([
              throwIfTimeout(
                `Stage action not completed before timeout ${actionTimeout}ms.`,
                actionTimeout,
              ),
              action(machineContext, userContext, additionalMachineData),
            ])
          }
        } catch (error) {
          _debug(`caught exception ${error}`, 'automation stage machine', 'd')
          if (error instanceof GlobalInterrupt) {
            _debug(
              'return early Global Interrupt',
              'automation stage machine',
              'd',
            )
            return null
          }
          if (error instanceof NeedsUserInputError) {
            _debug(
              'transition to needs user input',
              'automation stage machine',
              'd',
            )

            return onTriggeringEvent(
              TriggeringEventType.ON_NEEDS_USER_INPUT,
              machineContext,
              userContext,
              additionalMachineData,
            )
          }
          _debug('transition to error', 'automation stage machine', 'd')

          // Log to Sentry, but change level depending on how stage is
          // configured to handle the error
          const nextStageOnError =
            transitionNextStageMap?.[TriggeringEventType.ON_ERROR]
          if (
            !nextStageOnError ||
            nextStageOnError === defaultErroredStageKey
          ) {
            logError(error, 'automation-stage-machine')
          } else {
            logInfo(error, 'automation-stage-machine')
          }

          communication.sendSetLastExtensionErrorStackTrace(error.stack)
          communication.sendSetLastFailedAutomationData(
            getDebugContext(machineContext, userContext),
          )

          return onTriggeringEvent(
            TriggeringEventType.ON_ERROR,
            machineContext,
            userContext,
            additionalMachineData,
          )
        }

        _debug(
          'stage.onEnter action completed',
          'automation stage machine',
          'd',
        )
        return onTriggeringEvent(
          TriggeringEventType.ON_EVENT_ACTION_COMPLETED,
          machineContext,
          userContext,
          additionalMachineData,
        )
      },
      onExit: async () => {},
      // removed params since currently unused
      // async (machineContext, userContext) => {}
    },
    transitions: {
      [TriggeringEventType.ON_EVENT_ACTION_COMPLETED]: {
        action: (additionalMachineData) => {
          additionalMachineData.resumeData.clear()
        },
        getTargetStageKey: () =>
          transitionNextStageMap?.[
            TriggeringEventType.ON_EVENT_ACTION_COMPLETED
          ] || defaultNextStageKey,
      },
      [TriggeringEventType.ON_ERROR]: {
        action: (additionalMachineData) => {
          additionalMachineData.resumeData.clear()
        },
        getTargetStageKey: () =>
          transitionNextStageMap?.[TriggeringEventType.ON_ERROR] ||
          defaultErroredStageKey,
      },
      [TriggeringEventType.ON_INPUT_PAUSE]: {
        action: () => {},
        getTargetStageKey: () => defaultPausedStageKey,
      },
      [TriggeringEventType.ON_INPUT_RESUME]: {
        action: () => {},
        getTargetStageKey: () => key,
      },
      [TriggeringEventType.ON_NEEDS_USER_INPUT]: {
        action: () => {},
        getTargetStageKey: () =>
          transitionNextStageMap?.[TriggeringEventType.ON_NEEDS_USER_INPUT] ||
          defaultNeedsUserInputStageKey,
      },
    },
  }

  return stage
}

function createFinalStage({ type, key }: StageDef): Stage {
  const stage: Stage = {
    actions: {
      onEnter: async () => {},
      onExit: async () => {},
    },
    key,
    type,
    transitions: {},
  }

  return stage
}

function createPausedStage(
  { type, key, status }: StageDef,
  cancelStageKey: string,
): Stage {
  const stage: Stage = {
    type,
    key,
    status,
    actions: {
      onEnter: async (machineContext, userContext) => {
        _debug('stage.onEnter for pause stage', 'automation stage machine', 'd')
        const { platform } = userContext
        if (status) {
          setStoredMachineOverlayStatus(platform, status)
        }
      },
      onExit: async () => {
        _debug('exiting pause', 'automation stage machine', 'd')
      },
    },
    transitions: {
      [TriggeringEventType.ON_INPUT_RESUME]: {
        getTargetStageKey: (machineContext: MachineContext) => {
          const { stageKeyHistoryStack } = machineContext
          stageKeyHistoryStack.pop() // current stage
          const lastStageKey = stageKeyHistoryStack.pop()
          return lastStageKey
        },
      },
      [TriggeringEventType.ON_INPUT_CANCEL]: {
        getTargetStageKey: () => cancelStageKey,
      },
    },
  }

  return stage
}

function createNeedsUserInputStage(
  { type, key, status }: StageDef,
  cancelStageKey: string,
): Stage {
  const stage: Stage = {
    type,
    key,
    status,
    actions: {
      onEnter: async (machineContext, userContext) => {
        _debug(
          'stage.onEnter for needs user input stage',
          'automation stage machine',
          'd',
        )
        const { platform } = userContext
        if (status) {
          setStoredMachineOverlayStatus(platform, status)
        }
      },
      onExit: async () => {
        _debug('exiting needs user input', 'automation stage machine', 'd')
      },
    },
    transitions: {
      [TriggeringEventType.ON_INPUT_RESUME]: {
        getTargetStageKey: (machineContext: MachineContext) => {
          const { stageKeyHistoryStack } = machineContext
          stageKeyHistoryStack.pop() // current stage
          const lastStageKey = stageKeyHistoryStack.pop()
          return lastStageKey
        },
      },
      [TriggeringEventType.ON_INPUT_CANCEL]: {
        getTargetStageKey: () => cancelStageKey,
      },
    },
  }

  return stage
}

function createErroredStage(
  { type, key, status }: StageDef,
  cancelStageKey: string,
): Stage {
  const stage: Stage = {
    type,
    key,
    status,
    actions: {
      onEnter: async (machineContext, userContext) => {
        const { platform } = userContext
        _debug('stage.onEnter for error stage', 'automation stage machine', 'd')
        // Set the status for the overlay
        if (status) {
          setStoredMachineOverlayStatus(platform, status)
        }
      },
      onExit: async (machineContext, userContext, additionalMachineData) => {
        _debug('exiting error', 'automation stage machine', 'd')
        const { platform } = userContext
        const { persistentData } = additionalMachineData

        await persistentData.clear()
        setStoredMachineOverlayStatus(platform, null)
      },
    },
    transitions: {
      [TriggeringEventType.ON_INPUT_CANCEL]: {
        getTargetStageKey: () => cancelStageKey,
      },
      [TriggeringEventType.ON_INPUT_RETRY]: {
        action: () => {},
        getTargetStageKey: (machineContext: MachineContext) => {
          const { stageKeyHistoryStack } = machineContext
          return stageKeyHistoryStack[0]
        },
      },
    },
  }
  return stage
}

function constructAutomationSequenceStagesAndRegistry(
  { defaultSequenceStageDefs, controlFlowStageDefs }: AutomationSequenceDefs,
  onTriggeringEvent,
): StageRegistry {
  const stageRegistry = {}

  let pausedStageKey: string
  let cancelStageKey: string
  let erroredStageKey: string
  let finalStageKey: string
  let needsUserInputStageKey: string
  for (const stageDef of controlFlowStageDefs) {
    const { key } = stageDef
    if (key === ControlStageKey.PAUSED) {
      pausedStageKey = key
    } else if (key === ControlStageKey.CANCEL) {
      cancelStageKey = key
    } else if (key === ControlStageKey.ERRORED) {
      erroredStageKey = key
    } else if (key === ControlStageKey.FINAL) {
      finalStageKey = key
    } else if (key === ControlStageKey.NEEDS_USER_INPUT) {
      needsUserInputStageKey = key
    }
  }

  for (const stageDef of controlFlowStageDefs) {
    const { type, key } = stageDef
    if (key in stageRegistry) {
      throw new Error(`Key conflict in stage registry for ${key}`)
    }

    // Construct control stage based on type
    let stage: Stage
    if (type === StageType.PAUSED) {
      stage = createPausedStage(stageDef, cancelStageKey)
    } else if (type === StageType.CANCEL) {
      stage = createActionStage(
        stageDef,
        onTriggeringEvent,
        finalStageKey,
        pausedStageKey,
        erroredStageKey,
        needsUserInputStageKey,
      )
    } else if (type === StageType.ERRORED) {
      stage = createErroredStage(stageDef, cancelStageKey)
    } else if (type === StageType.FINAL) {
      stage = createFinalStage(stageDef)
    } else if (type === StageType.NEEDS_USER_INPUT) {
      stage = createNeedsUserInputStage(stageDef, cancelStageKey)
    }

    // Add stage to registry
    stageRegistry[key] = stage
  }

  for (const [index, stageDef] of defaultSequenceStageDefs.entries()) {
    const { type, key } = stageDef
    if (key in stageRegistry) {
      throw new Error(`Key conflict in stage registry for ${key}`)
    }

    // Create action stages
    let stage: Stage
    if (type === StageType.ACTION) {
      const defaultNextStageKey =
        index < defaultSequenceStageDefs.length - 1
          ? defaultSequenceStageDefs[index + 1].key
          : finalStageKey
      stage = createActionStage(
        stageDef,
        onTriggeringEvent,
        defaultNextStageKey,
        pausedStageKey,
        erroredStageKey,
        needsUserInputStageKey,
      )
    }

    // Add stage to registry
    stageRegistry[key] = stage
  }

  return stageRegistry
}

// Create automation sequence stage machine
// A specialized machine that has a default sequence that runs
// straight through in a happy case scenario, with other control flow
// stages for handling things like errors and user input
export function createAutomationSequenceMachine({
  machineName,
  initialStageKey,
  stageDefs,
}: AutomationStageMachineDef): Machine {
  const createStagesAndRegistryFn = (onTriggeringEvent) =>
    constructAutomationSequenceStagesAndRegistry(stageDefs, onTriggeringEvent)
  return createStageMachine({
    machineName,
    initialStageKey,
    createStagesAndRegistryFn,
    finalCleanUpFn: automationStageMachineCleanup,
  })
}
